'use strict'
angular.module('cb.lead-provider-service', [])
    .factory('LeadProviderService', ["ApiService", function (ApiService) {

        var LeadProviderService = {
            getAllLeadProviders: function () {
                try {
                    var url = '/webapi/api/v1/LeadProvider/GetAllLeadProviders';
                    return ApiService.promiseGet(url);
                }
                catch (e) {
                    console.error('Error!', e);
                }
			},

			getAllLeadProvidersAndOld: function () {
				try {
					var url = '/webapi/api/v1/LeadProvider/GetAllLeadProvidersAndOld';
					return ApiService.promiseGet(url);
				}
				catch (e) {
					console.error('Error!', e);
				}
			},

            getProviderById: function (id) {
                try {
                    var url = '/webapi/api/v1/LeadProvider/viewLeadProvider/' + id;
                    return ApiService.promiseGet(url);
                }
                catch (e) {
                    console.error('Error!', e);
                }
            },
            getLeadProvidersByStatus: function (data) {
                try {
                    var url = '/webapi/api/v1/LeadProvider/getLeadProvidersByStatus';
                    return ApiService.promisePost(url,data);
                }
                catch (e) {
                    console.error('Error!', e);
                }
            },
            getPriorityLeadProviders: function (data) {
                try {
                    var url = '/webapi/api/v1/LeadProvider/getPriorityLeadProviders';
                    return ApiService.promisePost(url,data);
                }
                catch (e) {
                    console.error('Error!', e);
                }
            },
            updateLeadProvider: function (data) {
                try {
                    var url = '/webapi/api/v1/LeadProvider/UpdateLeadProvider';
                    return ApiService.promisePost(url,data);
                }
                catch (e) {
                    console.error('Error!', e);
                }
            },
            search: function (data) {
                try {
                    var url = '/webapi/api/v1/LeadProvider/leadProviderSearch';
                    return ApiService.promisePost(url, data);
                }
                catch (e) {
                    console.error('Error!', e);
                }
            }
        };

        return LeadProviderService;
    }]);
